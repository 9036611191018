import {
  Box,
  Typography,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Container,
  Dialog,
  DialogContent,
  Divider,
} from "@mui/material";
import Link from "@mui/material/Link";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Controller,
  FieldValues,
  FormProvider,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { boolean, object, string } from "yup";
import { useNotificationStore } from "../../store/NotificationStore";
import { userRegistration, validateAccessKey } from "../../API/services";
import { success, failed } from "../Notification/NotificationMessages";
import { getErrorMessage } from "../../utils/util";
import AccessKeyDialog from "./AccessKeyDialog";
import logo from "../../assets/noshadow.png";
import { add, zip } from "lodash";

const Register = () => {
  const setNotification = useNotificationStore(
    (state) => state.setNotification
  );
  const navigate = useNavigate();
  const { accessCode } = useParams<{ accessCode: string }>();

  const [showAccessKeyModal, setShowAccessKeyModal] = useState(!accessCode);
  const [accessKey, setAccessKey] = useState(accessCode || "");
  const [validationResponse, setValidationResponse] = useState<any>({});
  const [showRegistrationComplete, setShowRegistrationComplete] =
    useState(false);
  const [hrPermission, setHrPermission] = useState(false);

  const validationSchema = object().shape({
    password: string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    state: string().required("State is required"),
    // province: string().required("Country is required"),
    hrPermission: boolean().optional(),
    organizationMailingAddress: string().required(
      "Organization Mailing Address is required"
    ),
    organizationAddress: string().required("Organization Address is required"),
    organizationAddressCity: string().required(
      "Organization Address City is required"
    ),
    organizationAddressState: string().required(
      "Organization Address State is required"
    ),
    organizationAddressZipCode: string().required(
      "Organization Address Zip Code is required"
    ),
    organizationAddressCountry: string().required(
      "Organization Address Country is required"
    ),
    organizationMailingAddressCity: string().required(
      "Organization Mailing Address City is required"
    ),
    organizationMailingAddressState: string().required(
      "Organization Mailing Address State is required"
    ),
    organizationMailingAddressZipCode: string().required(
      "Organization Mailing Address Zip Code is required"
    ),
    organizationMailingAddressCountry: string().required(
      "Organization Mailing Address Country is required"
    ),
    organizationWebsite: string()
      .optional()
      .matches(
        /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9#?=&_.-]*)?$/,
        "Invalid URL format"
      ),
      address: string().required("Address is required"),
city: string().required("City is required"),
zipCode: string().required("Zip Code is required"),
country: string().required("Country is required"),

  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const methods = useForm(formOptions);
  const { handleSubmit } = methods;

  useEffect(() => {
    if (accessKey) {
      console.log("Validating access key:", accessKey);
      validateKey();
    }
  }, [accessKey]);

  const validateKey = async () => {
    try {
      const response = await validateAccessKey({ accessKey });
      if (response.data) {
        console.log("Access key validation response:", response.data);
        setValidationResponse(response.data);
        if (response.data.registrationCompleted)
          setShowRegistrationComplete(true);
      }
    } catch (error: any) {
      console.error("Error validating access key:", error);
      setNotification(failed(getErrorMessage(error.response?.data)));
    }
  };

  const onFormSubmit = (data: FieldValues) => {
    console.log("Form data:", data);
    if (accessKey) {
      const payload = {
        firstName: validationResponse.user?.firstName || "",
        lastName: validationResponse.user?.lastName || "",
        email: validationResponse.user?.email || "",
        group: "GRP",
        fullName: `${validationResponse.user?.firstName || ""} ${validationResponse.user?.lastName || ""}`,
        phone: "",
        province: data.country,
        state: data.state,
        address: data.address,
        zipCode: data.zipCode,
        country: data.country,
        yearOfBirth: "",
        hiringManager: hrPermission,
        password: data.password,
        organizationAddress: data.organizationAddress,
        organizationMailingAddress: data.organizationMailingAddress,
        organizationAddressCity: data.organizationAddressCity,
        organizationAddressState: data.organizationAddressState,
        organizationAddressZipCode: data.organizationAddressZipCode,
        organizationAddressCountry: data.organizationAddressCountry,
        organizationMailingAddressCity: data.organizationMailingAddressCity,
        organizationMailingAddressState: data.organizationMailingAddressState,
        organizationMailingAddressZipCode:
          data.organizationMailingAddressZipCode,
        organizationMailingAddressCountry:
          data.organizationMailingAddressCountry,
        organizationWebsite: data.organizationWebsite,

        accessKey,
      };
      console.log("Submitting registration payload:", payload);
      userRegistration(payload)
        .then(() => {
          setNotification(success("Registered Successfully"));
          navigate("/login");
        })
        .catch((error) => {
          console.error("Error during registration:", error);
          setNotification(failed(getErrorMessage(error.response?.data)));
        });
    }
  };

  const handleAccessKeySubmit = (key: string) => {
    console.log("Access key submitted:", key);
    setAccessKey(key);
    setShowAccessKeyModal(false);
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <AccessKeyDialog
          showAccessKeyModal={showAccessKeyModal}
          onClose={() => navigate("/login")}
          onSubmit={handleAccessKeySubmit}
        />
        <Dialog
          open={showRegistrationComplete}
          onClose={() => {
            setShowRegistrationComplete(false);
            navigate("/login");
          }}
        >
          <DialogContent>
            <Typography variant="h6">
              This Entity has been registered already! Login to continue
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          </DialogContent>
        </Dialog>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            alt="Logo"
            style={{ height: "40px", width: "200px" }}
          />
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <FormProvider {...methods}>
            <Box
              component="form"
              onSubmit={handleSubmit(onFormSubmit)}
              sx={{ mt: 3 }}
            >
              {/* Organization Details */}
              <Typography variant="h6" sx={{ mb: 2 }}>
                Organization Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    label="Organization Name"
                    value={validationResponse.organizationName || ""}
                  />
                
                </Grid>
                <Grid item xs={12}>
                    <Controller
                      name="organizationWebsite"
                      control={methods.control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Organization Website"
                          error={!!methods.formState.errors.organizationWebsite}
                          helperText={
                            methods.formState.errors.organizationWebsite
                              ?.message
                          }
                          placeholder="https://www.example.com"
                        />
                      )}
                      
                      
                    />
                  </Grid>
              </Grid>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Organization Mailing Address Details
              </Typography>
              <Divider sx={{ mb: 3 }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="organizationMailingAddress"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Mailing Address"
                        error={
                          !!methods.formState.errors.organizationMailingAddress
                        }
                        helperText={
                          methods.formState.errors.organizationMailingAddress
                            ?.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="organizationMailingAddressCity"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="City"
                        error={
                          !!methods.formState.errors
                            .organizationMailingAddressCity
                        }
                        helperText={
                          methods.formState.errors
                            .organizationMailingAddressCity?.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="organizationMailingAddressState"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="State"
                        error={
                          !!methods.formState.errors
                            .organizationMailingAddressState
                        }
                        helperText={
                          methods.formState.errors
                            .organizationMailingAddressState?.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="organizationMailingAddressZipCode"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Zip Code"
                        error={
                          !!methods.formState.errors
                            .organizationMailingAddressZipCode
                        }
                        helperText={
                          methods.formState.errors
                            .organizationMailingAddressZipCode?.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="organizationMailingAddressCountry"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Country"
                        error={
                          !!methods.formState.errors
                            .organizationMailingAddressCountry
                        }
                        helperText={
                          methods.formState.errors
                            .organizationMailingAddressCountry?.message
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                Organization Address Details
              </Typography>
              <Divider sx={{ mb: 3 }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="organizationAddress"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Address"
                        error={!!methods.formState.errors.organizationAddress}
                        helperText={
                          methods.formState.errors.organizationAddress?.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="organizationAddressCity"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="City"
                        error={
                          !!methods.formState.errors.organizationAddressCity
                        }
                        helperText={
                          methods.formState.errors.organizationAddressCity
                            ?.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="organizationAddressState"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="State"
                        error={
                          !!methods.formState.errors.organizationAddressState
                        }
                        helperText={
                          methods.formState.errors.organizationAddressState
                            ?.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="organizationAddressZipCode"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Zip Code"
                        error={
                          !!methods.formState.errors.organizationAddressZipCode
                        }
                        helperText={
                          methods.formState.errors.organizationAddressZipCode
                            ?.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="organizationAddressCountry"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Country"
                        error={
                          !!methods.formState.errors.organizationAddressCountry
                        }
                        helperText={
                          methods.formState.errors.organizationAddressCountry
                            ?.message
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>

              {/* Account Owner */}
              <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                Account Owner
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="First Name"
                    value={validationResponse.user?.firstName || ""}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Last Name"
                    value={validationResponse.user?.lastName || ""}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    label="Email"
                    value={validationResponse.user?.email || ""}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="password"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Password"
                        type="password"
                        error={!!methods.formState.errors.password}
                        helperText={methods.formState.errors.password?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="address"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Address"
                        error={!!methods.formState.errors.address}
                        helperText={methods.formState.errors.address?.message}
                      />
                    )}
                  />
                </Grid> <Grid item xs={12} sm={6}>
                  <Controller
                    name="city"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="City"
                        error={!!methods.formState.errors.city}
                        helperText={methods.formState.errors.city?.message}
                      />
                    )}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="state"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="State"
                        error={!!methods.formState.errors.state}
                        helperText={methods.formState.errors.state?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="zipCode"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Zip Code"
                        error={!!methods.formState.errors.zipCode}
                        helperText={methods.formState.errors.zipCode?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="country"
                    control={methods.control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Country"
                        error={!!methods.formState.errors.country}
                        helperText={methods.formState.errors.country?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <FormControlLabel
                control={
                  <Checkbox
                    {...methods.register("hrPermission")}
                    checked={hrPermission}
                    onChange={(e) => setHrPermission(e.target.checked)}
                  />
                }
                label="Do you want Hiring Manager permission?"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Finish Registration
              </Button>
            </Box>
          </FormProvider>
        </Box>
      </Container>
    </>
  );
};

export default Register;
