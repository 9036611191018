import { Card, Tooltip, Typography } from "@mui/material";
import { fNumber } from "../../../utils/numberFormater";
interface ScoreCardType {
  title: string;
  minimum?: number;
  total: number;
  result?: boolean;
}
const ScoreCard = ({ title, total, result = true, minimum }: ScoreCardType) => {
  return (
    <Card
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: "center",
        bgcolor: result ? "#D1E9FC" : "#f5aca4",
        color: "#061B64",
        flexGrow: 1,
      }}
    >
      <>
        <Typography variant="h3">{total}</Typography>
        {minimum && (
          <Typography variant="caption" sx={{ opacity: 0.72 }}>
            Minimum: {minimum}
          </Typography>
        )}

        <Tooltip title={`${title} - ${result ? "Passed" : "Failed"}`} arrow>
          <Typography
            fontWeight={600}
            variant="subtitle2"
            sx={{
              opacity: 0.72,
              width: '50%',
              margin: '0 auto', // Centers the Typography element horizontally
              textAlign: 'center', // Centers the text inside the Typography element
              whiteSpace: 'nowrap', // Prevents text wrapping
              overflow: 'scroll', // Hides the overflow without showing ellipsis
              cursor: 'pointer',
            }}
          >
            {title}
          </Typography>
        </Tooltip>
      </>
    </Card>
  );
};
export default ScoreCard;
