import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getListOfOrganizations, resendActivationEmail } from "../../API/services";
import { organizationDTO } from "../../utils/Types/DTO";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Container,
  CssBaseline,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Chip,
  styled,
  Stack,
  Typography,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import TitleBanner from "../../components/TitleBanner/TitleBanner";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { URLS } from "../../constants";
import { formatDate, getErrorMessage } from "../../utils/util";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { success, failed } from "../../components/Notification/NotificationMessages";
import { useNotificationStore } from "../../store/NotificationStore";
type props = {
  bgColor: string;
};
const StyleChip = styled(Chip)(({ bgColor }: props) => ({
  backgroundColor: bgColor,
  borderRadius: 3,
}));
const OrgListing = () => {
  const navigate = useNavigate();
   const setNotification = useNotificationStore(
      (state) => state.setNotification
    );

  const [orgList, setOrgList] = useState<organizationDTO[]>();
  useEffect(() => {
    const getOrgsList = async () => {
      const response = await getListOfOrganizations();
      if (response.data) {
        setOrgList(response.data);
      }
    };
    getOrgsList();
  }, []);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOrg, setSelectedOrg] = useState<organizationDTO | null>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, org: organizationDTO) => {
    setAnchorEl(event.currentTarget);
    setSelectedOrg(org);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedOrg(null);
  };
  const resendEmail= async(orgId:string)=>{

  try {

    await resendActivationEmail(orgId);

    setNotification(success("Email Sent Successfully"));
    
  } catch (error:any) {
    
    setNotification(failed(getErrorMessage(error.response.data)));
            console.log(error);
  }
  }

  return (
    <Container component="main" sx={{ padding: 0 }}>
      <CssBaseline />
      <TitleBanner
        title={"Organization Listing"}
        rightChild={
          <Stack
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/entity/add`);
            }}
            alignItems={"center"}
            direction={"row"}
            spacing={1}
          >
            <AccountBalanceIcon style={{ color: "white" }} />
            <Typography
              align="center"
              color={"white"}
              variant="h1"
              fontSize={18}
              sx={{ opacity: 1 }}
            >
              Add Organization
            </Typography>
          </Stack>
        }
      />
      <TableContainer style={{ marginTop: "10px" }} component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Access Key</TableCell>
              <TableCell>Registration Completed</TableCell>
              <TableCell>Date Added</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orgList &&
              orgList.map((org) => (
                <TableRow key={org.id}>
                  <TableCell>{org?.organizationName}</TableCell>
                  <TableCell>{org?.accessKey}</TableCell>
                  <TableCell>
                    <StyleChip
                      label={org?.registrationCompleted.toString()}
                      bgColor={
                        org?.registrationCompleted ? "#d1fcd7" : "#f5aca4"
                      }
                    />
                  </TableCell>
                  <TableCell sx={{ minWidth: 110 }}>
                    {org?.createdDateTime
                      ? formatDate(org?.createdDateTime)
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {/* Three Dots Icon */}
                    <IconButton
                      onClick={(event) => handleMenuOpen(event, org)}
                    >
                      <MoreVertIcon />
                    </IconButton>

                    {/* Dropdown Menu */}
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl) && selectedOrg?.id === org.id}
                      onClose={handleMenuClose}
                    >
                      <MenuItem
                        onClick={() => {
                          navigate(`/assessment/management/add/${btoa(org.id)}`);
                          handleMenuClose();
                        }}
                      >
                        Assessment Management
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          navigate(`/admin/list/org/${btoa(org.id)}`);
                          handleMenuClose();
                        }}
                      >
                        View Admins
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          navigate(URLS?.edit_entity, {
                            state: {
                              orgId: org?.id,
                              organizationName: org?.organizationName,
                              organizationPhone: org?.organizationPhone,
                              organizationAddress: org?.organizationAddress,
                              organizationAddressZipCode:
                                org?.organizationAddressZipCode,
                              organizationAddressState:
                                org?.organizationAddressState,
                              organizationAddressCountry:
                                org?.organizationAddressCountry,
                              organizationMailingAddress:
                                org?.organizationMailingAddress,
                              organizationMailingAddressZipCode:
                                org?.organizationMailingAddressZipCode,
                              organizationMailingAddressState:
                                org?.organizationMailingAddressState,
                              organizationMailingAddressCountry:
                                org?.organizationMailingAddressCountry,
                              organizationWebsite: org?.organizationWebsite,
                              organizationAddressCity: org?.organizationAddressCity,
                              organizationMailingAddressCity: org?.organizationMailingAddressCity,
                              
                            },
                          });
                          handleMenuClose();
                        }}
                      >
                        Edit Organization
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          navigate(`/add/admin/${btoa(org.id)}`);
                          handleMenuClose();
                        }}
                      >
                        Add Admin
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          navigate(`/supervisors/add/${btoa(org.id)}`);
                          handleMenuClose();
                        }}
                      >
                        Add Supervisor
                      </MenuItem>
                      {
                        !org.registrationCompleted &&(
                           <MenuItem
                        onClick={() => {
                          resendEmail(org.id)
                          handleMenuClose();
                        }}
                      >
                        Resend Activation Email
                      </MenuItem>
                        )
                      }
                     
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};
export default OrgListing;
